import axios from 'axios';
import {
    ServerUrl,
    authHeader
} from '../../utils/common';
import {
    handleApiError,
} from '../../utils/error';
class ApiService {

    static async login(postData) {
        try {
            const response = await axios.post(ServerUrl + '/v1/login', postData, { headers: authHeader() });
            return response.data;
        } catch (error) {
            let newError = handleApiError(error);
            throw newError; 
        }
    }

    static async idamLogin(code, session_state) {

        try {
            const response = await axios.get(ServerUrl + '/v1/idam-callback?code=' + code + '&session_state=' + session_state, { headers: authHeader() });
            return response.data;
        } catch (error) {
            let newError = handleApiError(error);
            throw newError; 
        }
    }
}

export default ApiService;
