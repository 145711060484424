import React from "react";
import "../../App.css";
import AlertMessage from "../../components/AlertMessage";
import HeaderBar from "./Header";
import FooterBar from "./Footer";
import { useSelector } from "react-redux";
import LoadingOverlay from "../../components/LoadingOverlay";

const MainLayout = ({ children }) => {
  const { loader } = useSelector((store) => store.spinner);

  return (
    <div className="grid-container">
      <HeaderBar />
      <div className="main-content-area">
        
        <div className="main-content container-fluid mt-2 mb-5">
          {loader && <LoadingOverlay />}
          <AlertMessage />
          {children}
        </div>
      </div>
      <FooterBar />
    </div>
  );
};

export default MainLayout;
