import React from 'react';
import { format } from 'date-fns'
import { createBrowserHistory } from 'history';
//import moment from "moment"
import moment from 'moment-timezone';
export const history = createBrowserHistory();
export const PUBLIC_URL = ""
//export const ServerUrl = "http://localhost:8080/api";
export const ServerUrl = "http://api.patidarborewell.com/api";
export const DATE_FORMAT = "DD/MM/YYYY";


export const params = (name, url = window.location.href) => {
    //name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const truncateString = (str, maxLength = 20) => {
    if (str) {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.substring(0, maxLength) + '...';
        }
    }
}
// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return {};
}

// check promo code is available using start date and end date
export const getDateYYYYMMDD = (date) => {
    if (date) {
        return format(
            date,
            'yyyy-MM-dd'
        );
    } else {
        return format(
            new Date(),
            'yyyy-MM-dd'
        );
    }

}

// return the currecy format value
export const currencyFormat = () => {
    const currecyFormat = 'en-US';
    const currency = 'INR';
    return { locale: currecyFormat, currency: currency }
}

// return the currecy format value
export const formattedValueWithSymbol = (int) => {
    //if (int) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'INR'
        }).format(int);
   // }
}
// return the currecy format value
export const formattedValueWithoutSymbol = (int) => {
    //if (int) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
        }).format(int);
    //}
}
// return the currecy format value
export const formattedNumberValue = (int) => {
   // if (int) {
        return new Intl.NumberFormat('en-US', {
        }).format(int);
    //}
}
// return the currecy format value
export const formattedNumberValueWithDecimal = (int) => {
    //if (int) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
        }).format(int);
    //}
}

// return the permissions data from the session storage
export const getPermissions = (ele) => {
    const user = getUser();
    const permissionsStr = sessionStorage.getItem('permissions');
    if (permissionsStr && user.roleId !== 1) {
        return permissionsStr.includes(ele);
    } else {
        return {};
    }
}

// return the token from the session storage
export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('permissions');
}

// set the token and user from the session storage
export const setUserSession = (token, user, permissions) => {

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    if (permissions) {
        const newArr = permissions.map((Data) => {
            return Data.name;
        });
        sessionStorage.setItem('permissions', JSON.stringify(newArr));
    }

}
export const authHeader = () => {
    const token = getToken();
    if (token) {
        return {
            'x-access-token': token,
            'User-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
    } else {
        return {};
    }
}
export const authFormHeader = () => {
    const token = getToken();
    if (token) {
        return {
            'x-access-token': token,
            "Content-Type": "multipart/form-data",
            'User-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
    } else {
        return {};
    }
}

export const makeArray = (data) => {
    let newArray = [];
    if (data) {
        let resp = data.split("+");
        newArray = resp.map(function (singleElement) {
            return { value: singleElement };
        })
    }
    return newArray;
}

export const getStatus = () => {
    let statusArray = [
        { id: 1, value: 'Active' },
        { id: 2, value: 'Inactive' },
        { id: 3, value: 'Deleted' }
    ];
    return statusArray;
}


export const getLabel = (val) => {

    if (Number(val) === 1) {
        return (<span className='success'>Active</span>);
    } else if (Number(val) === 2) {
        return (<span className='warn'>Inactive</span>);
    } else {
        return (<span className='danger'>Deleted</span>);
    }
}
export const getDateTime = () => {
    return format(
        new Date(),
        'dd/MM/yyyy hh:mm'
    );
}

export const dateFormat = (val) => { // remove timezone and create new date
    if (val !== '' && val !== undefined && val !== null) {

        var arr1 = val.split('T');
        return format(
            new Date(arr1[0]),
            'dd/MM/yyyy'
        );

    }
}
export const dateWithMonthNameFormat = (val) => { // remove timezone and create new date
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    if (val !== '' && val !== undefined && val !== null) {
        let arr1 = val.split('T');
        return new Date(arr1[0]).toLocaleDateString("en-US", options)
    } else {
        return new Date().toLocaleDateString("en-US", options);
    }
}

export const makeString = (val) => {
    if (val !== '' && val !== undefined && val !== null) {
        return String(val);
    } else {
        return '';
    }
}

export const checkValue = (val) => {
    if (val !== '' && val !== undefined && val !== null) {
        return val;

    } else {
        return '';
    }
}
export const dateWithoutZone = (val) => {
    if (val !== '' && val !== undefined && val !== null) {
        let date = moment(val).format("DD/MM/YYYY");
        return date;
    }
}
export const dateTimeWithoutZone = (val) => {
    if (val !== '' && val !== undefined && val !== null) {
        //const date = moment.tz(val, 'UTC').format('DD/MM/YYYY hh:mm');
        let date = moment(val).format("DD/MM/YYYY HH:mm:ss");
        return date;
    }
}


export const getYears = () => {
    const startYear = 2018;
    const currentYear = new Date().getFullYear(); // Gets the current year

    const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
    return yearsArray
}
export const getYear = () => {

    const currentYear = new Date().getFullYear(); // Gets the current year
    return currentYear
}

export const getNewDate = (val) => {
    if (val) {
        const date = new Date(val);
        const month = date.getMonth(); // Adding 1 to get the correct month number
        const day = date.getDate();
        const year = date.getFullYear();
        //console.log(date,month,day,year)
        return new Date(year, month, day)
    } else {
        return '';
    }

}

export const getDateTimeString = () => {
    const now = new Date();
    return moment(now).format("DD/MM/YYYY hh:mm:ss");
}




