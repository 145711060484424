import { createSlice } from '@reduxjs/toolkit'

export const alertMsg = createSlice({
    name: 'alertMsg',
    initialState: {
        value: {},
    },
    reducers: {
        show: (state, action) => {
            state.value = action.payload
        },
        hide: (state) => {
            state.value = {}
        },

    },
})

// Action creators are generated for each case reducer function
export const { show, hide } = alertMsg.actions
export const getAlertData = (state) => state.value
export default alertMsg.reducer