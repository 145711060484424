import React, { Suspense } from "react";

// ================= components imports =======================
import LoaderContainer from "./LoaderContainer";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderContainer />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
