import React from 'react'
import AllRoutes from '../src/routes/Index'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <AllRoutes key="routes" />
    </div>

  );
}

export default App;