import React from "react";
import { Navigate } from "react-router";
import { Form, Button, Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components
import { useForm } from "react-hook-form";
import { setUserSession, getUser } from "../../utils/common";
import AuthLayout from "../layouts/AuthLayout";
import ApiService from "./ApiService";
import { show, hide } from "../../store/alertMessage/reducers";
import { setSpinner } from "../../store/spinner/reducers";
import { useDispatch } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Login = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      email: "",
      password: "",
    }
  });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(hide());
    dispatch(setSpinner(true));
    try {
      const response = await ApiService.login(data);
      let result = response.data;

      if (response.status === "success") {
        setUserSession(result.token, result.user, result.permissions);
      } else {
        dispatch(setSpinner(false));
        dispatch(show({
          status: response.status,
          message: response.message,
        }));
      }
    } catch (error) {
      dispatch(setSpinner(false));
      dispatch(show(error));
    }
  };

  const userData = getUser();
  if (userData.id > 0) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <AuthLayout>
      <Container className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="border p-4 rounded shadow-sm" style={{ maxWidth: '400px', width: '100%' }}>
          <Form onSubmit={handleSubmit(onSubmit)} className="form">
            <h4 className="text-center mb-4">Login</h4>

            <Form.Group controlId="email">
              <Form.Label>
                E-mail <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                })}
                placeholder="Enter email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="password" className="mt-3">
              <Form.Label>
                Password <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                {...register("password", {
                  required: "Password is required",
                  validate: value =>
                    value.trim() !== "" || "Password cannot be empty",
                })}
                isInvalid={!!errors.password}
                placeholder="Enter password"
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.password && errors.password.message}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default Login;
