//import { formattedValueWithoutSymbol } from "../../utils/common";
// reducers.js
const initialState = {
    cart: [],
    orderDetails: {},
    totalOrderPrice: 0
};

const newOrderReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'REFRESH_CART':
            return {
                orderDetails:{},
                cart: [],
                totalOrderPrice: 0
            };

        case 'ADD_TO_CART':
            const newItem = action.payload;
            const cartItems = state.cart

            let mergedArray = [...cartItems];
            let total = 0;
            for (let i = 0; i < newItem.length; i++) {
                let found = false;

                for (let j = 0; j < mergedArray.length; j++) {
                    if (JSON.stringify(mergedArray[j].article_number) === JSON.stringify(newItem[i].article_number)) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    mergedArray.push(newItem[i]);
                    total = total + newItem[i].total_price
                }
            }
            //console.log(mergedArray)
            let totalOrderPrice = mergedArray.reduce((acc, item) => acc + parseFloat(item.total_price), 0);
            return {
                ...state,
                cart: mergedArray,
                totalOrderPrice: totalOrderPrice
            };

        case 'REMOVE_FROM_CART':
            const deletedProduct = state.cart.find(item => item.article_number === action.payload);
            return {
                ...state,
                totalOrderPrice: state.totalOrderPrice - deletedProduct.total_price,
                cart: state.cart.filter(item => item.article_number !== action.payload),
            };
        case 'UPDATE_QTY':

            let { article_number, qty } = action.payload;
            qty = qty > 0 ? qty : 1;
            
            const updateProduct = state.cart.find(item => item.article_number === article_number);
            const sell_price = parseFloat(updateProduct.sell_price).toFixed(2);
            let total_price = parseFloat(sell_price * qty).toFixed(2);
            
            let UpdateData = state.cart.map(item =>
                item.article_number === article_number ? { ...item, qty, total_price } : item
            );
            //console.log(UpdateData)
            
            let totalOrderPrice2 = UpdateData.reduce((acc, item) => acc + parseFloat(item.total_price), 0);
            //console.log(qty,sell_price,total_price,totalOrderPrice2)

            return {
                ...state,
                cart: UpdateData,
                totalOrderPrice: totalOrderPrice2
            };
        case 'UPDATE_ORDER_DETAILS':
            return {
                ...state,
                orderDetails: action.payload
            };
        case 'CLEAR_ALL_ORDER_DETAILS':
            return {
                orderDetails:{},
                cart: [],
                totalOrderPrice: 0
            };
        default:
            return state;
    }
};

export default newOrderReducer;