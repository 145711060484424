export const handleApiError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data instanceof Blob && error.response.data.type === 'application/json') {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const errorMessage = JSON.parse(reader.result);
                    resolve({ status: 'error', message: errorMessage.message });
                };
                reader.readAsText(error.response.data);
            });
        } else {
            return { status: 'error', message: error.response.data.message };
        }
    } else if (error.request) {
        // The request was made but no response was received
        return { status: 'error', message: error.message }
    } else {
        // Something happened in setting up the request that triggered an Error
        return { status: 'error', message: error.message }
    }
}