import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AlertMessage = () => {
  const getAlertMsgData = useSelector((state) => state.alertMsg.value);
  const alertMsgStatus = getAlertMsgData ? getAlertMsgData.status : null;
//console.log(getAlertMsgData)
  useEffect(() => {
    if (getAlertMsgData) {
      if (alertMsgStatus === 'success') {
        toast.success(getAlertMsgData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(getAlertMsgData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    }
  }, [getAlertMsgData, alertMsgStatus, toast]);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default AlertMessage;
