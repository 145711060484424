import { createSlice } from '@reduxjs/toolkit'

export const spinner = createSlice({
    name: 'spinner',
    initialState: {
        loader: false
    },
    reducers: {
        setSpinner: (state, action) => {
            state.loader = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSpinner } = spinner.actions
export default spinner.reducer