import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useLocation, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faLock,
  faCog,
  faDashboard,
  faSignOutAlt,
  faIndianRupee,
  faMoneyBills,
  faReceipt,
  faList,
  faFile,
  faBoreHole,
  faFileInvoice,
  faBarChart,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import {
  PUBLIC_URL,
  getUser,
  getPermissions,
  truncateString,
  removeUserSession,
} from "../../utils/common";
import Constants from "../../utils/constants.js";
import { hide } from "../../store/alertMessage/reducers.js";
const HeaderBar = ({ userName, profileImageUrl }) => {
  const location = useLocation();
  const routeName = location.pathname.split("/").pop();

  // Permission checks
  const viewDashboard = getPermissions(Constants.VIEW_ADMIN_DASHBOARD);
  const viewAllUsers = getPermissions(Constants.VIEW_ALL_USERS);
  const viewAllRoles = getPermissions(Constants.VIEW_ALL_ROLES);
  const viewAllPermissions = getPermissions(Constants.VIEW_ALL_PERMISSIONS);
  const viewAllBoreBill = getPermissions(Constants.VIEW_BORE_BILL);
  const viewAllBoreExpenseAmount = getPermissions(
    Constants.VIEW_BORE_EXPENSE_AMOUNT
  );
  const viewAllBoreReceivedAmount = getPermissions(
    Constants.VIEW_BORE_RECEIVED_AMOUNT
  );
  // vikas
  const viewAgentPerformance = getPermissions(Constants.VIEW_AGENT_PERFORMANCE);
  const viewDepthReport = getPermissions(Constants.BORE_DEPTH_REPORT);
  const viewAllReport = getPermissions(Constants.VIEW_BORE_REPORT);
  const viewAgentReport = getPermissions(Constants.VIEW_AGENT_REPORT);
  const viewCommission = getPermissions(Constants. VIEW_BORE_COMMISSION);

  const updateProfilePermissions = getPermissions(
    Constants.UPDATE_USER_PROFILE
  );

  const dispatch = useDispatch();
  const userData = getUser();
  const [authID, setUserId] = useState(userData.id);
  const [, setLogoutModalOpen] = useState(false);

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const logout = () => {
    closeLogoutModal();
    dispatch(hide());
    try {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure you want to logout?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setUserId("");
              removeUserSession();
            },
            className: "logout-yes btn btn-primary",
          },
          {
            label: "No",
            onClick: () => {},
            className: "logout-no btn btn-primary-outline",
          },
        ],
      });
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (!authID) {
    return <Navigate to="/" />;
  }
  return (
    <header className="bg-dark">
      <div className="container-fluid">
        <Navbar bg="dark" expand="lg" variant="dark">
          <Navbar.Brand href="/" className="logo">
            <span className="logo-head-1">Patidar</span>{" "}
            <span className="logo-head-2">Borewell</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="mx-auto">
              {authID && viewDashboard ? (
                <Nav.Link
                  href="/dashboard"
                  className={routeName === "dashboard" ? "activeMenu" : "menu"}
                >
                  <FontAwesomeIcon icon={faDashboard} /> Dashboard
                </Nav.Link>
              ) : null}

              {authID &&
                (viewAllUsers || viewAllRoles || viewAllPermissions) && (
                  <NavDropdown
                    title={
                      <span>
                        <FontAwesomeIcon icon={faUser} /> Admin Menu
                      </span>
                    }
                    id="admin-menu"
                    className={
                      routeName === "users-list" ||
                      routeName === "role-list" ||
                      routeName === "permission-list"
                        ? "activeMenu"
                        : "menu"
                    }
                  >
                    {viewAllUsers && (
                      <NavDropdown.Item href={`${PUBLIC_URL}/users-list`}>
                        <FontAwesomeIcon
                          icon={faUsers}
                          className={
                            routeName === "users-list"
                              ? "activeMenu"
                              : "menu me-2"
                          }
                        />
                         User Management
                      </NavDropdown.Item>
                    )}
                    {viewAllRoles && (
                      <NavDropdown.Item href={`${PUBLIC_URL}/role-list`}>
                        <FontAwesomeIcon
                          icon={faCog}
                          className={
                            routeName === "role-list"
                              ? "activeMenu"
                              : "menu me-2"
                          }
                        />
                        Role Management
                      </NavDropdown.Item>
                    )}
                    {viewAllPermissions && (
                      <NavDropdown.Item href={`${PUBLIC_URL}/permission-list`}>
                        <FontAwesomeIcon
                          icon={faLock}
                          className={
                            routeName === "permission-list"
                              ? "activeMenu"
                              : "menu me-2"
                          }
                        />
                        Permission Management
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                )}
              {authID &&
                (viewAllBoreBill ||
                  viewAllBoreExpenseAmount ||
                  viewAllBoreReceivedAmount) && (
                  <NavDropdown
                    title={
                      <span>
                        <FontAwesomeIcon icon={faBoreHole} /> Bore Menu
                      </span>
                    }
                    id="Bore-menu"
                    className={
                      routeName === "bore-bill-list" ||
                      routeName === "bore-expense-amount-list" ||
                      routeName === "bore-received-amount-list"
                        ? "activeMenu"
                        : "menu"
                    }
                  >
                    {viewAllBoreBill && (
                      <NavDropdown.Item href={`${PUBLIC_URL}/bore-bill-list`}>
                        <FontAwesomeIcon
                          icon={faMoneyBills}
                          className={
                            routeName === "bore-bill-list"
                              ? "activeMenu me-2"
                              : "menu me-2"
                          }
                        />
                        Bore Bill
                      </NavDropdown.Item>
                    )}

                    {viewAllBoreExpenseAmount && (
                      <NavDropdown.Item
                        href={`${PUBLIC_URL}/bore-expense-amount-list`}
                      >
                        <FontAwesomeIcon
                          icon={faFileInvoice}
                          className={
                            routeName === "bore-expense-amount-list"
                              ? "activeMenu me-2"
                              : "menu me-2"
                          }
                        />
                        Bore Expense Amount
                      </NavDropdown.Item>
                    )}
                    {viewAllBoreReceivedAmount && (
                      <NavDropdown.Item
                        href={`${PUBLIC_URL}/bore-received-amount-list`}
                      >
                        <FontAwesomeIcon
                          icon={faIndianRupee}
                          className={
                            routeName === "bore-received-amount-list"
                              ? "activeMenu me-2"
                              : "menu me-2"
                          }
                        />
                        Bore Received Amount
                      </NavDropdown.Item>
                    )}
                    {viewCommission && (
                      <NavDropdown.Item
                        href={`${PUBLIC_URL}/bore-commission`}
                      >
                        <FontAwesomeIcon
                          icon={faPercent}
                          className={
                            routeName === "bore-commission"
                              ? "activeMenu me-2"
                              : "menu me-2"
                          }
                        />
                        Bore Commission
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                )}

              {authID && (viewDepthReport || viewAllReport || viewAgentReport || viewAgentPerformance) && (
                <NavDropdown
                  title={
                    <span>
                      <FontAwesomeIcon icon={faReceipt} /> Report Menu
                    </span>
                  }
                  id="Bore-menu"
                  className={
                    routeName === "agent-performance" ? "activeMenu" : "menu"
                  }
                >
                  {viewDepthReport && (
                    <NavDropdown.Item href={`${PUBLIC_URL}/depth-report`}>
                      <FontAwesomeIcon
                        icon={faList}
                        className={
                          routeName === "depth-report"
                            ? "activeMenu me-2"
                            : "menu me-2"
                        }
                      />
                      Depth Report
                    </NavDropdown.Item>
                  )}
                  {viewAllReport && (
                    <NavDropdown.Item href={`${PUBLIC_URL}/view-all-report`}>
                      <FontAwesomeIcon
                        icon={faFile}
                        className={
                          routeName === "view-all-report"
                            ? "activeMenu me-2"
                            : "menu me-2"
                        }
                      />
                      View All Report
                    </NavDropdown.Item>
                  )}
                  {viewAgentReport && (
                    <NavDropdown.Item href={`${PUBLIC_URL}/agent-report`}>
                      <FontAwesomeIcon
                        icon={faFile}
                        className={
                          routeName === "agent-report"
                            ? "activeMenu me-2"
                            : "menu me-2"
                        }
                      />
                      Agent Report
                    </NavDropdown.Item>
                  )}
                  {viewAgentPerformance && (
                    <NavDropdown.Item href={`${PUBLIC_URL}/agent-performance`}>
                      <FontAwesomeIcon
                        icon={faBarChart}
                        className={
                          routeName === "agent-performance"
                            ? "activeMenu me-2"
                            : "menu me-2"
                        }
                      />
                      Agent Performance
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              )}
            </Nav>
            <Nav className="ml-auto">
              {authID  && (
                  <NavDropdown
                    title={
                      <span>
                        Hello,{" "}
                        <strong>{truncateString(userData.name, 15)}</strong>{" "}
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    }
                    id="admin-menu"
                    className="menu"
                  >
                    {updateProfilePermissions && (
                      <NavDropdown.Item href={`${PUBLIC_URL}/update-profile`}>
                        <FontAwesomeIcon
                          icon={faUsers}
                          className={
                            routeName === "users-list"
                              ? "activeMenu me-2"
                              : "menu me-2"
                          }
                        />
                        Profile
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      onClick={() => {
                        logout();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className={
                          routeName === "users-list"
                            ? "activeMenu me-2"
                            : "menu me-2"
                        }
                      />
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default HeaderBar;
