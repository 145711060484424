import React from "react";
const FooterBar = () => {
  return (
    <footer className="footer">
      <div className="container-fluid d-flex justify-content-between">
        <span className="text-white">Patidar Borewell © 2024</span>
        <span className="text-white">Powered by <span className="logo-head-1">I-smart Info</span> <span className="logo-head-2">Systems</span></span>
      </div>
    </footer>
  );
};

export default FooterBar;
