import React, {useEffect } from 'react';
import { params } from '../../utils/common';
import AuthLayout from "../layouts/AuthLayout";
import { hide } from '../../store/alertMessage/reducers';
import { setSpinner } from "../../store/spinner/reducers";
import { useDispatch } from 'react-redux';

const IdamError = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(hide());
        dispatch(setSpinner(false));
    }, [dispatch]);

    return (
        <AuthLayout>
            <>
                {params('msg')}
            </>
        </AuthLayout>
    );
};

export default IdamError;
