import { createSlice } from '@reduxjs/toolkit'

export const utils = createSlice({
    name: 'utils',
    initialState: {
        open: false
    },
    reducers: {
        toggleModal: (state, action) => {
            state.open = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleModal } = utils.actions

export default utils.reducer