import React from 'react'
import MainLayout from "../layouts/MainLayout"
class Page404 extends React.Component {
  render() {
    return (
      <>
        <MainLayout>
          <h1>
            Page 404
          </h1>
        </MainLayout>
      </>
    );
  }
}
export default Page404 