import { configureStore } from '@reduxjs/toolkit'
import alertReducer from './alertMessage/reducers'
import createOrderReducer from './newOrder/reducers';
import spinnerReducer from './spinner/reducers';
import utilsReducer from './utils';

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('createOrder');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const { spinner, alertMsg, ...stateToSave } = state;
    const serializedState = JSON.stringify(stateToSave);
    sessionStorage.setItem('createOrder', serializedState);
  } catch {
    // Ignore write errors
  }
};

const persistedState = loadState();

const store = configureStore({
  reducer: {
    alertMsg: alertReducer,
    createOrder: createOrderReducer,
    spinner: spinnerReducer,
    utils: utilsReducer
  },
  preloadedState: persistedState
});

store.subscribe(() => {
  saveState(store.getState());
});

export default store;